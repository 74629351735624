import React from 'react'
import './Footer.css'
import logo from '../../../images/Group 806.svg'
import facebook from '../../../images/Facebook.png'
import google from '../../../images/Google+1.png'
import twitter from '../../../images/Twitter.png'
import youtube from '../../../images/Youtube.png'
import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"

export default function Footer({ footer, copyright }) {
  return (
    <div className='footer-container' >
      <div className='container service-container footer-main-containers mt-5'>
        <div className="row footer-main-container ">
          <div className="col-12 col-lg-12 col-xl-4 d-flex justify-content-center pb-5">

            <div className="d-none d-sm-block">
              <div className="footer-square-first">
                <div className="footer-square-second">
                  <div className="footer-square-third">
                    <div className="footer-small-square-first"></div>
                    <div className="footer-small-square-second"></div>
                    {/* <img src={footer.logo.gatsbyImageData} alt="logo" className='footer-logo' /> */}
                    <GatsbyImage image={footer.logo.gatsbyImageData} alt="image" />
                    <p className='footer-email mb-2'>{footer.email}</p>
                    <h1 className='footer-number m-0'>{footer.phoneNumber}</h1>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-block d-sm-none">
              <div className="footer-square-third">
                <div className="footer-small-square-first"></div>
                <div className="footer-small-square-second"></div>
                <GatsbyImage image={footer.logo.gatsbyImageData} className='footer-logo' alt="image"  />
                {/* <img src={footer.logo.fluid.src} alt="logo" className='footer-logo' /> */}
                <p className='footer-email mb-2'>{footer.email}</p>
                <h1 className='footer-number m-0'>{footer.phoneNumber}</h1>
              </div>
            </div>

          </div>
          <div className="col-12 col-lg-12 col-xl-8">
            <div className="row" style={{ marginBottom: '80px' }}>
              {footer.footerHeading.map((p, i) => {
                return (
                  <div className={`${i == 2 ? 'col-12' : 'col-6 '} col-md-4 mb-3`}>
                    <h1 className='footer-header-item'>{p.title}</h1>
                    {p.footerSection.map((x) => {
                      return (
                        <>
                          {i == 2 ?
                            <p className='footer-subHeader-item'>{x.text}</p>
                            :
                            <Link  className='footer-subHeader-item-link'>{x.text}</Link>
                          }
                        </>
                      )
                    })}
                    {i === 2 &&
                      <div className='row'>
                        <div className="social-media-square">
                          <img src={facebook} alt="facebook" className='m-0' />
                        </div>
                        <div className="social-media-square">
                          <img src={twitter} alt="" className='m-0' />
                        </div>
                        <div className="social-media-square">
                          <img src={google} alt="" className='m-0' />
                        </div>
                        <div className="social-media-square">
                          <img src={youtube} alt="" className='m-0' />
                        </div>
                      </div>}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="copyright d-flex justify-content-between pt-lg-5">
          <p className='copyright-text'>{copyright.title}</p>
        </div>
      </div>
    </div>
  )
}
