import React, { useEffect, useState } from 'react'
import './Navbar.css'
import Logo from '../../../images/AppsileLogo.svg'
import Call from '../../../images/phone.svg'
import { Link, navigate } from 'gatsby';
import Scrollspy from 'react-scrollspy'
import Bars from '../../../images/bar.svg'

export default function Navbar({ navbar, themeColor, ...props }) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const items = navbar.map((p) => p.to)


  const style = {
    // Adding media query..
    '@media (max-width: 960px)': {
      backgroundColor: themeColor
    },
  };


  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition]);

  function scrollHandler() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }




  return (
    <div className="d-flex justify-content-center" style={{ height: '75px', background: scrollPosition > 10 ? '#fff' : 'none', position: 'fixed', top: 0, zIndex: 111, width: '100%' }}>
      <nav class="container navbar navbar-expand-lg navbar-light navbar-style">
        <Link to="/" class="navbar-brand" ><img src={Logo} className="main-logo mb-0" alt="logo" /></Link>
        <button class="navbar-toggler pr-0" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          {/* <span class="navbar-toggler-icon"></span> */}
          <img src={Bars} alt="bars" class="toggler-bar-icon m-0" />
        </button>
        <div class="collapse navbar-collapse" style={style} id="navbarSupportedContent">
          {/* <ul class="navbar-nav ml-auto"> */}
          <Scrollspy
            offset={-100}
            items={items} currentClassName={scrollPosition > 10 ? 'actives-scroll' : 'actives'} className='navbar-nav ml-auto'>
            {navbar.map(p => {
              return (p.title === "Contact US" ?
                <li className='px-1 contact-btn-container '>
                  <a href={`/${p.hRef}`}> <div className='contact-btn-nav d-flex align-items-center' ><img src={Call} alt="callIcon" className='pr-2' />{p.title}</div></a>
                </li>
                :
                <li class="nav-item">
                  <Link class="nav-link" to={`/${p.hRef}`} style={{ color: scrollPosition > 10 ? '#1D244B' : '#fff' }} >{p.title}<span class="sr-only">(current)</span></Link>
                  {/* <a class="nav-link" onClick={(e) => {
                    const path = window && window.location.pathname.split("/");
                    if (path.at(-1) !== "") {
                      e.preventDefault();
                      navigate(`/${p.hRef}`)
                    }

                  }} href={`${p.hRef}`} >{p.title}<span class="sr-only">(current)</span></a> */}
                </li>
              )
            })}
          </Scrollspy>
          {/* </ul> */}
        </div>
      </nav>
    </div>
  )
}
