import React from 'react'
import './Header.css'
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import User from '../../../images/Ellipse 155.png'
import DesktopMockup from '../../../images/Rectangle 483.png'
import MobileMockup from '../../../images/Rectangle 484.png'
import TabletMockup from '../../../images/Rectangle 482.png'


export default function Header({ header, ...props }) {
  return (
    // <section id="home" className='pt-5' style={{ height: '100vh' }} >
    //   <div className="container header-container" style={{ height: '100%',display: 'flex', flexDirection: 'column' }} >
    //     {header.actionImage.map((p, i) => {
    //       return (
    //         <div className={`${i === 0 ? '-gradient-first' : i === 1 ? 'header-square-second' : i === 2 ? 'header-square-third' : i === 3 ? 'header-square-four' : ''} d-sm-flex justify-content-center align-items-center d-none d-sm-block`}>
    //           <GatsbyImage layout={'fullWidth'} image={p.gatsbyImageData} alt="image" />
    //         </div>
    //       )
    //     })}
    //     <div>
    //       <h1 className='header-text mt-5'>
    //         {header.title.split(',').reduce((all, cur) => [
    //           ...all,
    //           <br />,
    //           <span className="text-color" > {cur}</span>
    //         ])}
    //       </h1>
    //       <p className='d-header-description'>{header.description.description}</p>
    //     </div>
    //     <div className='d-flex justify-content-center' style={{ overflow: 'hidden', marginBottom: 'auto', marginTop: 'auto' }}>
    //       {/* <img src="https://picsum.photos/id/237/5000/5000" className='img-fluid' alt="" /> */}
    //       <GatsbyImage image={header.image.gatsbyImageData} alt="image" imgClassName='img-fluid' objectFit='contain' />
    //     </div>
    //   </div>
    // </section >
    <section id="home" className='pt-5 header-banner-sec'>
      <div className='right-section-bg d-none d-lg-block'></div>
      <div className="container pt-3 pt-lg-5">
        <div className="row">
          <div className="col-12 col-lg-5">
            <h1 className='header-text-con'>{header.title}</h1>
            <p className='header-text-des-con'>{header.description.description}</p>
            <div className='d-none d-sm-none d-md-none d-lg-block'>
              <button className='header-know-more-btn'>know more</button>
            </div>
          </div>
          <div className="col-12 col-lg-7">
            {header?.mockup?.map((p) => {
              return (
                <div className='review-bg-container'>
                  <div className='review-container d-flex align-items-center'>
                    <div className='pr-3'>
                      {/* <img src={User} alt="" className='review-img m-0' /> */}
                      <GatsbyImage image={p.profile.gatsbyImageData} alt="image" className='review-img m-0' />
                    </div>
                    <div>
                      <p className='mb-2 review-card-description m-0'>{p.description.description}</p>
                      <p className='m-0 review-card-description-bold'>{p.userName}<span className='review-card-descriptions'>{`-${p.profession}`}</span></p>
                    </div>
                  </div>
                  <div className="header-square-gradient">
                    <div className="desktop-view">
                      {/* <img src={DesktopMockup} className="review-mockup-img m-0" alt="image-mockup" /> */}
                      <GatsbyImage image={p.desktopView.gatsbyImageData} alt="image" className="review-mockup-img m-0" />
                    </div>
                    <div className="mobile-view">
                      {/* <img src={MobileMockup} className="review-mockup-img m-0" alt="image-mockup" /> */}
                      <GatsbyImage image={p.mobileView.gatsbyImageData} alt="image" className="review-mockup-img m-0" />
                    </div>
                    <div className="tablet-view">
                      {/* <img src={TabletMockup} className="review-mockup-img m-0" alt="image-mockup" /> */}
                      <GatsbyImage image={p.tabletView.gatsbyImageData} alt="image" className="review-mockup-img m-0" />
                    </div>
                  </div>
                </div>
              )
            })}

            <div className='d-block d-sm-block d-md-block d-lg-none pt-3 pt-lg-5 d-flex justify-content-center'>
              <button className='header-know-more-btn'>know more</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
